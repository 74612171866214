const Constant = {
  action: {
    MODAL_OPEN: "modal-open",
    MODAL_CLOSE: "modal-close",
    TOGGLE_BUTTON: "toggle-button",
    ON_FROM_LOAD: "on-from-load",
    ON_TO_LOAD: "on-to-load",
    ON_PLACE_FROM_CHANGED: "on-place-from-changed",
    ON_PLACE_TO_CHANGED: "on-place-to-changed",
    SUBMIT: "submit",
    ON_REPORT_CLICK: "on-report-click",
    ON_LIKE_CLICK: "on-like-click",
    ON_DISLIKE_CLICK: "on-dislike-click",
    POPOVER_CLOSE: "popover-close",
    ON_COPY_CLICK: "on-copy-click",
    ON_FB_SHARE_CLICK: "no-fb-share-click",
    SWAP: "swap",
    AUTH_ELELEMT_TYPE_POPUP: "popup",
    AUTH_ELELEMT_TYPE_PAGE: "page",
    RIGHT: "right",
    LEFT: "left",
    TOP: "top",
    BOTTOM: "bottom",
  },
  label: {
    STARTING_LOCATION: "Starting Location",
    DESTINATION_LOCATION: "Destination Location",
    STARTING_POINT: "Starting Point",
    DESTINATION_POINT: "Destination Point",
    LOCATION_FILTERS: "Location Filters",
    APPLY_FILTER: "Apply Filter",
    CANCEL: "Cancel",
    SOCKET_TYPES: "Socket Types",
    CHARGE_POINT_SPEEDS: "Charge point speeds",
    NETWORKS: "Networks",
    GO: "Go!",
    SUBMIT: "Submit",
    FEEDBACK: "Rate your experience",
    ACCESS: "Access",
    VEHICLE_SEGMENT: "Vehicle Segment",
    OPEN_24X7: "Open 24/7",
    CHARGE_PAID: "Charge Paid",
    CHARGE_FREE: "Charge Free",
    INFORMATION: "Information",
    CONNECTORS: "Connectors",
    STATIONS: "Stations",
    AVAILABLE: "Available",
    UNAVAILABLE: "Unavailable",
    LOGIN: "Login",
    REGISTER: "Register",
    NEW_ACCOUNT: "New to",
    ALREADY_HAVE_ACCOUNT: "Already have an account?",
    OPERATOR_SUPPORT: "Operator Support",
    LOCATION_INFORMATION: "Location Information",
    LOCATION_TIME_INFORMATION: "Location Opening Timings:",
    NEARBY_AMENITIES: "Amenities:",
    REQUEST_FOR_CHARGER: "Request for charger",
    PLAN_YOUR_TRIP: "Plan your trip",
    FEEDBACK_POPOVER: "Feedback",
    REPORT_ISSUE: "Report Issue",
    FLAG_AN_ISSUE: "Report an issue",
    ISSUE_TITLE: `Please select an issue that you've encountered:`,
    OTHERS: "Other",
    FAVOURITE_LIST: "Favourite List",
    SAVED_TRIP: "Saved Trip",
    REPORTED_ISSUE: "Reported Issues",
    REPORT_ISSUE_BTN: "Report Issue",
    CUSTOMER_REVIEWS: "Customer Reviews",
    PHOTOS: "Photos",
    SHARE: "Share",
    SAVE: "Save",
    SEND_LINK: "Send a link",
    LINK_TO_SHARE: "Link to share",
    LEAVE_CHECKIN: "Leave a checkin",
    CHECKIN: "Check Ins (n)",
    LIKE: "Like",
    DISLIKE: "Dislike",
    TRIP_PLANNER: "Trip Planner",
    VIEW_DIRECTIONS: "View Directions",
    ISSUE_PARA: `Please let us know the details of the issue you're experiencing. Details will be shared with our operations team.`,
    VIEW_ON_GOOGLE_MAP: "View on Google Map",
    ADD_TO_TRIP_PLANNER: "Add to Trip",
    REMOVE_TRIP: "Remove Trip",
    SAVE_TRIP: "Save Trip",
  },
  placeholder: {
    LIKE: "Please let us know what you like?",
    DISLIKE: "Please let us know what you dislike?",
  },
  openDB: {
    name: "map_ev",
    objectStore: "get_locations",
    version: 1,
  },
  socketType: {
    CCS_TYPE_2: "CCS Type 2",
    TYPE_2: "Type 2",
    CHADEMO: "CHAdeMo",
    TYPE_1: "Type 1",
    TYPE_1_AC: "Type 1 AC",
    "3_PIN": "3 PIN",
    GBT: "GBT",
    J1772: "J1772",
    CCS_TYPE_1: "CCS Type 1",
    CCS_SAE: "CCS/SAE",
    THREE_Phase: "Three Phase",
    WAll: "Wall",
    IEC60309: "IEC60309",
  },
  networkFilter: {
    AARGO: "ARRgo",
    ATHER: "Ather",
    CHARGE_GRID: "Charge Grid",
    CHARGE_ZONE: "Charge Zone",
    EFILL: "Efill",
    ELECTRIVA: "Electriva",
    EVRE: "EVRe",
    FORTUM: "Fortum",
    GOEGO: "Goego",
    RELUX: "Relux Electric",
    STATIQ: "Statiq",
    TATA: "Tata Power",
    VOLTTIC: "Volttic",
    JIO_BP: "Jio Bp",
    XOBOLT: "XObolt",
    ZEON: "Zeon Charging",
    BECIL: "BECIL",
    CHARGEMILES: "ChargeMiles",
    KROPEX: "Kropex Motors",
    NTPC: "NTPC",
    INSTAVOLT: "InstaVolt",
    OLA: "OLA",
    REIL: "REIL",
    TCIL: "TCIL",
    IOCL: "IOCL",
    KHT: "KHT Motors",
    Kazam: "Kazam",
    EESL: "EESL",
    GENERIC: "Generic",
    VERDE_MOBILITY: "VER",
    MINOOKI: "Minooki Mobility",
    PLUGZMART: "Plugzmart",
    BBMP: "BBMP",
    OEM: "OEM",
    PGCIL: "PGCIL",
    DELTA: "Delta",
    MOVEONEV: "MoveonEV",
    AMIKCHARGE: "AmikCharge",
  },
  chargePointSpeed: {
    SPEED_SLOW: "Slow",
    SPEED_FAST: "Fast",
    SPEED_RAPID: "Rapid",
    SPEED_ULTRA_RAPID: "Ultra Rapid",
  },
  vehicleSegment: {
    SEGMENT_2_WHEELER: "segment2Wheeler",
    SEGMENT_3_WHEELER: "segment3Wheeler",
    SEGMENT_4_WHEELER: "segment4Wheeler",
    SEGMENT_SWIPE: "segmentSwipe",
    SEGMENT_2_WHEELER_DISABLED: "segment2WheelerDisabled",
    SEGMENT_3_WHEELER_DISABLED: "segment3WheelerDisabled",
    SEGMENT_4_WHEELER_DISABLED: "segment4WheelerDisabled",
    SEGMENT_SWIPE_DISABLED: "segmentSwipeDisabled",
    SEGMENT_2_WHEELER_GENERIC: "segment2WheelerGeneric",
    SEGMENT_3_WHEELER_GENERIC: "segment3WheelerGeneric",
    SEGMENT_4_WHEELER_GENERIC: "segment4WheelerGeneric",
  },
  imageMapper: {
    FAULT_CONNECTOR_IMAGE: "faultConnectorImage",
    ICON_14: "icon14",
    ICON_15: "icon15",
    ICON_16: "icon16",
    ICON_17: "icon17",
  },
  paths: {
    HOME: "/",
    HOME_: "/home",
    REQUEST_CHARGER: "/request-charger",
    LOGIN: "/login",
    REGISTER: "/register",
    FEEDBACK: "/feedback",
    FORGOT_PASSWORD: "/forget-password",
    MAP: "/map",
    WHY_CHARGEMILES: "/why-chargemiles",
    CONTACT_US: "contact-us",
    PRODUCTS: "product",
  },
  methods: {
    ADD_FAVOURITES: "addToFavourites",
    REMOVE_FAVOURITES: "removeFromFavourites",
    LOCATION_DETAIL: "getLocationDetail",
    REPORT_ISSUE: "reportIssue",
    GET_REPORTED_ISSUE: "getReportedIssues",
    REACTION: "reaction",
    GET_REACTIONS: "getReactions",
    UPLOAD_IMAGE: "uploadImage",
    REMOVE_IMAGE: "removeImage",
    GET_USER: "getUser",
    CREATE_TRIP: "createTrip",
    UPDATE_TRIP: "updateTrip",
    GET_TRIP: "getTrip",
    DELETE_TRIP: "deleteTrip",
  },
  message: {
    NO_FAVOURITE_LIST: "No Favourite Locations",
    ISSUE_REPORTED_SUCCESS:
      "Thanks for reporting the issue.  The issue has been reported to the back office team",
    NO_ROUTE_BETWEEN_LOCATION: "No Routes Available",
    NO_SAVED_TRIP: "No Saved Trips",
  },
  types: {
    UNDEFINED: "undefined",
    NULL: null,
    EMPTY: "",
  },
  googleMap: {
    DEFAULT_ZOOM: 14,
    CLUSTER_DEFAULT_MAX_ZOOM: 14,
  },
  connectorStatus: {
    available: [
      "Available",
      "Preparing",
      "Suspended EV",
      "SuspendedEV",
      "Suspended EVSE",
      "SuspendedEVSE",
    ],
    inUse: ["Charging", "Finishing"],
    unAvailable: ["Unavailable", "Reserved", "Faulted"],
  },
  chargerStatus: ["Active", "Inactive"],
  chargeConnectDisconnectMsg: {
    forConnect: "Please securely connect the charging gun to the EV",
    forDisconnect: "Please put the connector back to Charger",
  },
  domainList: {
    CHARGEMILES: "chargemiles",
    MOVEONEV: "moveonev",
    AMIK: "amikcharge",
    AMIKTECHNOLOGIES: "amiktechnologies",
    TECHVOLTNETWORK:"techvoltnetwork",
    AVVTECH: "avvtech",
  },
  domainProviderList: {
    chargemiles: "ChargeMiles",
    moveonev: "MoveonEV",
    amikcharge: "AMIK Technologies",
    amiktechnologies: "AMIK Technologies",
    techvoltnetwork:"Techvolt Network",
    avvtech: "AvvTech",
  },
};

export default Constant;
